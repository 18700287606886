











import {Component, Vue} from 'vue-property-decorator'
import {VCard, VCardText, VProgressCircular} from 'vuetify/lib'

@Component({
    components: {
        VCard,
        VCardText,
        VProgressCircular
    },
    computed: {}
})
export default class LoadingDialog extends Vue {
    //  META DATA

    //  UI DATA

    //  DATA

}
