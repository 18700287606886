
























































































































import LoadingDialog from '~/components/app/loading-dialog.vue'
import {CourseType, CourseTypeText, Order} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import _ from 'lodash'
import moment from 'moment'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {VAlert, VBtn, VCard, VCardActions, VCardText, VCardTitle, VDialog, VSimpleTable, VSpacer, VTextarea, VTextField} from 'vuetify/lib'

@Component({
    components: {
        LoadingDialog,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VSpacer,
        VBtn,
        VTextarea,
        VTextField,
        VSimpleTable,
        VAlert
    },
    computed: {}
})
export default class RefundDialog extends Vue {
    @Prop({default: false}) show: boolean

    @Prop() order: Order
    @Prop() courseId: string

    //  META DATA
    CourseType = CourseType
    CourseTypeText = CourseTypeText
    //  UI DATA
    isLoading: boolean = false

    yearlyRefundedCount: number = 0
    orderRefundedCount: number = 0
    onlineWatchProgress: number = 0
    liveRefundDeadline: number = 0

    //  DATA
    refundRecords: any[] = []


    get showDialog() {
        return this.show
    }

    set showDialog(show) {
        this.$emit('update:show', show)
    }

    get targetCourse() {
        return _.find(this.order.courses, item => item.course_id === this.courseId)
    }

    get courseType() {
        if (this.targetCourse) {
            if (this.order.offline_data[this.courseId]) {
                return this.targetCourse.offline_data.trial ? CourseType.TRIAL : CourseType.LIVE
            } else {
                return CourseType.ONLINE
            }
        }
        return ''
    }

    get isRefundWithin3Times() {
        const refunds = _.filter(this.refundRecords, item => item.timestamp > moment().subtract(1, 'year').valueOf())
        const orderId = _.uniq(_.map(refunds, 'order_id'))
        this.yearlyRefundedCount = orderId.length
        return orderId.length < 3
    }

    get isCourseIdFirstTimeRefund() {
        const allCourse = _.map(this.refundRecords, 'course')
        const allCourseId = _.map(allCourse, 'course_id')
        return !allCourseId.includes(this.courseId)
    }

    get isSameOrderRefundWithin3() {
        const sameOrder = _.filter(this.refundRecords, {order_id: this.order._id})
        this.orderRefundedCount = sameOrder.length
        return sameOrder.length < 3
    }

    get isWatchProgressWithin50() {
        if (this.targetCourse) {
            this.onlineWatchProgress = Math.floor(this.targetCourse.progress * 100)
            return this.targetCourse.progress <= 0.5
        }
        return true
    }

    get isWithin14Days() {
        return Date.now() < moment(this.order.paid_time).endOf('day').add(14, 'day').valueOf()
    }

    get isBeforeRefundDeadline() {
        if (this.targetCourse) {
            const selClassId = this.order.offline_data[this.courseId].time_slot
            const selClass = _.find(this.targetCourse.offline_data.classes, {id: selClassId})
            const lessonAfterPaidTime: any = _.filter(selClass!.lessons, item => item.start > Date.now())
            const lessonLeft = lessonAfterPaidTime.length
            if (lessonLeft >= 2) {
                this.liveRefundDeadline = lessonAfterPaidTime[1].start
                return Date.now() <= this.liveRefundDeadline
            } else if (lessonLeft === 1) {
                this.liveRefundDeadline = lessonAfterPaidTime[0].start
                return Date.now() <= this.liveRefundDeadline
            } else {
                return false
            }
        }
        return false
    }

    async created() {
        this.isLoading = true
        await this.fetchRefundRecords()
        this.isLoading = false
    }

    async fetchRefundRecords() {
        const res = await createRequest('/refund/refund-records', 'get', {member_id: this.order.member_id, limit: 0}).send()
        this.refundRecords = res.data.refund_records
    }

    rulesColor(state) {
        return state ? 'text-primary' : 'text-danger'
    }

    rulesIcon(state) {
        return state ? 'as-checkmark-circle-fill text-primary' : 'as-close-circle-fill text-danger'
    }

    async refund() {
        try {
            await this.$confirm(`Sure to refund ${this.courseId}?`)
            await createRequest('/courses/order/' + this.order._id + '/refund/' + this.courseId, 'post').send()
            this.$emit('refunded', this.courseId)
        } catch {
        }
        this.close()
    }

    close() {
        this.$emit('update:show', false)
    }


}
