

























import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Order, OrderStatus, PaymentMethod} from '~/components/data-class/data-class'
import PaymentOption from '~/components/order/payment'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {}
})
export default class OrdersPhonesDialog extends Vue {
    @Prop()
    order: Order

    @Prop({
        default: () => {
            return false
        }
    })
    visible!: boolean

    paymentFilters = PaymentOption
    paymentMethod: PaymentMethod = PaymentMethod.NOT_SELECTED

    @Watch('order', {deep: true})
    vChanged() {
        this.paymentMethod = this.order.payment_method
    }

    getPaymentText(p: PaymentMethod) {
        const found = this.paymentFilters.find(item => {
            return item.value === p
        })
        return found ? found.text : ''
    }
    async submit() {
        await createRequest('/courses/order/' + this.order._id + '/status', 'patch', {}, {
            status: OrderStatus.PAID,
            payment_method: this.paymentMethod
        }).send()

        this.order.status = OrderStatus.PAID
        this.order.payment_method = this.paymentMethod
        this.dialogVisible = false
        this.$message.success('Updated')
        this.$emit('submitted')
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }
}
