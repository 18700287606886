




































































































































































































































































































































































































































































import {MailingStatus, NoteLanguage, Order, OrderCourse, OrderStatus} from '~/components/data-class/data-class'
import PaymentDialog from '~/components/order/payment-status-dialog.vue'
import RefundDialog from '~/components/order/refund-dialog.vue'
import ResendDialog from '~/components/order/resend-dialog.vue'
import VerificationDialog from '~/components/order/verification-dialog.vue'
import {sfLookup, updateLocations} from '~/components/sf-express/sf-location-storage'
import WaybillScannerDialog from '~/components/waybill-scanner-dialog.vue'
import OrderMixin from '~/mixins/order-mixin'
import {createRequest} from '~/utils/network-request'
import {OrderPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

@Component({
    components: {
        RefundDialog,
        VerificationDialog,
        WaybillScannerDialog,
        PaymentDialog,
        ResendDialog
    },
    metaInfo() {
        return {
            title: 'Blog Detail'
        }
    }
})
export default class PurchaseDetails extends mixins(OrderMixin) {
    static permission = OrderPermission.Order

    NoteLanguage = NoteLanguage
    popoverVisible = {}

    // SFExpress
    orderShipping: { id: string, address: string, weekday: string, weekend: string } = {
        id: '',
        address: '',
        weekday: '',
        weekend: ''
    }

    //change phone
    newPhone: string = ''
    phonePopover: boolean = false

    noteLangText = {
        [NoteLanguage.NONE]: '-',
        [NoteLanguage.CHINESE]: '中文',
        [NoteLanguage.ENGLISH]: '英文',
        [NoteLanguage.BOTH]: '中英對照'
    }

    orderNumber = '' // order order number

    orderDetails: Order = new Order()

    sfExpressOptions: { value: string, title: string }[] = []
    selectedShipping = ''

    orgPriceSums = 0

    priceSums = 0
    priceSumsString = ''


    offer = 0 // course offer
    sums: string[] = [] // course sums

    waybillNumberEdit = false

    dialogAddWaybillNumber = false
    dialogChangeShipping = false
    dialogChangeCourse = false
    dialogPaymentVisible = false
    verificationDialogVisible = false
    dialogResendVisible = false
    dialogViewResendVisible = false

    inputWaybillNumber

    changePriceCourse: OrderCourse = new OrderCourse()
    changedPrice = 0

    //  refund
    showRefund: boolean = false
    refundTarget: string = ''

    get orgPrice() {
        const dict = {}
        for (const course of this.orderDetails.courses) {
            dict[course.course_id] = course.price
        }
        return dict
    }

    async created() {
        this.orderNumber = this.$route.params['_id']
        await updateLocations()
        this.sfExpressOptions = Object.keys(sfLookup).map((code) => {
            return {value: code, title: sfLookup[code].address}
        })
        this.getOrderDetails()
    }

    // refund with once course
    async refundCourse(index, courseId) {
        try {
            await this.$confirm(`Sure to refund ${courseId}?`)
            await createRequest('/courses/order/' + this.orderNumber + '/refund/' + courseId, 'post').send()
            this.orderDetails.courses[index].refunded = true
        } catch {
        }
    }

    handleChangeCourseClose(done) {
        this.$confirm('Sure to close?')
            .then(() => {
                done()
                this.dialogChangeCourse = false
            })
    }

    handleChangeAddressClose(done) {
        this.$confirm('Sure to close?')
            .then(() => {
                done()
                this.dialogChangeShipping = false
            })
    }

    changeAddressSubmit() {
        createRequest('/courses/order/' + this.orderNumber + '/status', 'patch', {}, {
            status: this.orderDetails.status,
            shipping_code: this.selectedShipping,
            payment_method: this.orderDetails.payment_method
        }).send()
            .then(() => {
                this.orderShipping = sfLookup[this.selectedShipping]
                this.orderDetails.shipping_code = this.selectedShipping
                this.selectedShipping = ''
                this.dialogChangeShipping = false
            })
    }

    async codeScanned(awbNo: string) {
        await createRequest('/courses/order/' + this.orderNumber + '/mailing-status', 'patch', {},
            {
                mailing_status: MailingStatus.MAILED,
                waybill_number: awbNo
            }).send()
        await this.getOrderDetails()
    }

    getOrderDetails() {
        createRequest('/courses/order/' + this.orderNumber, 'get').send()
            .then((res) => {
                this.orderDetails = res.data.order

                this.newPhone = this.orderDetails.phone
                this.offer = this.orderDetails.discount

                // Shipping finder
                this.orderShipping = sfLookup[this.orderDetails.shipping_code]

                if (this.orderDetails.waybill_number) {
                    this.inputWaybillNumber = this.orderDetails.waybill_number
                }

                this.priceSums = 0
                for (const course of this.orderDetails.courses) {
                    this.orgPriceSums += course.original_price
                    this.priceSums += course.price
                    this.popoverVisible[course.course_id] = false
                }
            })
    }

    // total method
    getSummaries(param) {
        const {columns} = param
        columns.forEach((column, index) => {
            // Total
            switch (index) {
                case 0:
                    this.sums[index] = 'Total'
                    return
                case 1:
                    this.sums[index] = ''
                    return
                case 2:
                    this.sums[index] = ''
                    return
                case 3:
                    this.sums[index] = this.priceSumsString
                    return
                case 4:
                    this.sums[index] = '$' + this.priceSums
                    return
                case 5:
                    this.sums[index] = ''
                    return
            }
        })

        return this.sums
    }

    // drop down item select
    async changeStatus(status: OrderStatus) {
        if (status === OrderStatus.PAID && this.orderDetails.price > this.orderDetails.diamond_used) {
            this.dialogPaymentVisible = true
        } else {
            await createRequest('/courses/order/' + this.orderNumber + '/status', 'patch', {}, {status}).send()
            await this.getOrderDetails()
        }
    }

    // drop down item select
    async changeMStatus(status: MailingStatus) {
        if (status === MailingStatus.TB_MAILED && [MailingStatus.MAILED, MailingStatus.NO_NEED].includes(this.orderDetails.mailing_status)) {
            this.dialogResendVisible = true
        } else if (status === MailingStatus.MAILED) {
            this.dialogAddWaybillNumber = true
        } else {
            await createRequest('/courses/order/' + this.orderNumber + '/mailing-status', 'patch', {},
                {mailing_status: status}).send()
            this.$set(this.orderDetails, 'mailing_status', status)
        }
    }

    async changePayment(payment) {
        await createRequest('/courses/order/' + this.orderNumber + '/status', 'patch', {}, {
            status: OrderStatus.PAID,
            payment_method: payment
        }).send()
        this.orderDetails.status = OrderStatus.PAID
        this.orderDetails.payment_method = payment
    }

    waybillNumberCancelEdit() {
        this.waybillNumberEdit = false
    }

    waybillNumberConfirmEdit() {
        this.waybillNumberEdit = false
        this.orderDetails.waybill_number = this.inputWaybillNumber
        // API
        if (this.inputWaybillNumber !== '' && this.inputWaybillNumber !== null && this.inputWaybillNumber !== undefined)
            createRequest('/courses/order/' + this.orderNumber + '/status', 'patch', {}, {
                status: 2,
                waybill_number: this.inputWaybillNumber
            }).send()
                .then(() => {
                    this.orderDetails.status = 2
                })
    }

    changePricePopover(course: OrderCourse) {
        this.changePriceCourse = course
        this.changedPrice = course.price
    }

    async changePrice() {
        const courseId = this.changePriceCourse.course_id
        this.$set(this.popoverVisible, courseId, false)
        await createRequest(`/courses/order/${this.orderNumber}/${courseId}/price`, 'patch', {},
            {price: this.changedPrice}).send()
        this.getOrderDetails()
        this.$message.success('Updated')
    }

    async changeNote(oc: OrderCourse) {
        await createRequest(`/courses/order/${this.orderNumber}/${oc.course_id}/note`, 'patch', {},
            {note: oc.note}).send()
        this.getOrderDetails()
        this.$message.success('Updated')
    }

    async changePhone() {
        this.phonePopover = false
        await createRequest(`/courses/order/${this.orderNumber}/phone`, 'patch', {},
            {phone: this.newPhone}).send()
        this.getOrderDetails()
        this.$message.success('Updated')
    }

    async langChanged(oc: OrderCourse, lang: NoteLanguage) {
        await createRequest(`/courses/order/${this.orderNumber}/note-language`, 'patch', {},
            {[oc.course_id]: lang}).send()
        oc.language = lang
        this.$message.success('Updated')
    }

    refundExpired(course: OrderCourse) {
        const date = new Date(this.orderDetails.paid_time)
        date.setDate(date.getDate() + course.refund_days)
        const limitTime = date.getTime()
        return Date.now() > limitTime
    }

    clickRefund(idx, courseId) {
        this.showRefund = true
        this.refundTarget = courseId
    }
}

