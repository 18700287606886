

































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import {Order, OrderStatus, PaymentMethod} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import PaymentOption from '~/components/order/payment'
import { initImageViewer } from '~/utils/misc'
import ImageDialogMixin from '~/mixins/image-dialog-mixin'
import {mixins} from 'vue-class-component'

@Component({
    components: {}
})
export default class VerificationDialog extends mixins(ImageDialogMixin) {
    paymentOptions = PaymentOption
    failVisible = false
    failReason = ""
    OrderStatus = OrderStatus

    @Prop({
        default: () => {
            return new Order()
        }
    })
    order: Order

    created() {
    }

    mounted() {}

    async accept(paymentMethod: PaymentMethod) {
        await createRequest('/courses/order/' + this.order._id + '/status', 'patch', {}, {
            status: OrderStatus.PAID,
            payment_method: paymentMethod
        }).send()
        this.order.status = OrderStatus.PAID
        this.order.payment_method = paymentMethod
        this.dialogVisible = false
    }
    async reject() {
        await createRequest('/courses/order/' + this.order._id + '/status', 'patch', {}, {
            status: OrderStatus.VERIFICATION_FAILED,
            failed_reason: this.failReason
        }).send()
        this.order.status = OrderStatus.VERIFICATION_FAILED
        this.order.failed_reason = this.failReason
        this.failVisible = false
        this.dialogVisible = false
    }

    get imageUrl() {
        return this.order.verification_image
    }
}
